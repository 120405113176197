import { Link } from "react-router-dom";
function Brand() {
  return (
    <Link className='header-link-home' to='home'>
      EKR | Dev Portfolio
    </Link>
  );
}

export default Brand;
